import { FC, memo } from 'react';
import s from './AppFooter.module.scss';
import Logo from '@/shared/assets/logo.svg?react';
import { menu } from '../../../AppHeader/model/const/menu';
import { useMediaQuery } from 'usehooks-ts';
import { TABLET_MEDIA_QUERY } from '@/shared/const/reponsive.const';
import classNames from "classnames";
import {Link} from "react-router-dom";
import {SocialList} from "@/entities/Social/ui/SocialList/SocialList";

interface PropsTypes {}

export const AppFooter: FC<PropsTypes> = memo(() => {
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  return (
    <footer className={`container`}>
      <div className={s.AppFooter}>
        <div className={classNames(s.logoWrapper, s.block)}>
          <Logo className={s.logo} />
          <div className={s.logoTitle}>
            <p>Конкурс краси</p>
            <p>“Міс Житомир”</p>
          </div>
        </div>
        {isTablet && (
          <div className={classNames(s.block, s.menuWrapper)}>
            <div className={s.blockTitle}>Меню</div>
            <div className={s.menu}>
              <ul className={s.menuList}>
                {menu.map(item => (
                  <li key={item.value} className={s.menuItem}>
                    <Link to={item.route} className={s.menuLink}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {isTablet && (
          <div className={classNames(s.block, s.locationWrapper)}>
            <div className={s.blockTitle}>Локація</div>
            <div className={s.text}>
              <p>Житомир вулиця Перемоги, 24</p>
              <p>Концерт-хол “Big room”</p>
            </div>
            <div className={s.text}>+380 63 235 55 00</div>
          </div>
        )}
        <div className={classNames(s.block, s.dateTimeWrapper)}>
          <div className={s.blockTitle}>Дата та час</div>
          <div className={s.blockContent}>
            <div className={s.text}>15.07.2024</div>
            <div className={s.text}>12:00</div>
          </div>
        </div>
        <div className={classNames(s.block, s.socialWrapper)}>
          <div className={s.blockTitle}>Ми в соціальних мережах</div>
          <div className={s.social}>
            <SocialList/>
          </div>
        </div>
      </div>
    </footer>
  );
});
