export const router = {
  main: () => '/',
  participator: (participatorId: string) => `/participator/${participatorId}`,
  votes: () => '/votes',
  tours: () => '/tours',
  jury: () => '/jury',
  news: () => '/news',
  newsOne: (newsId: string) => `/news/${newsId}`,
  winners: () => '/winners',
}
