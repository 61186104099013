import { FC, memo, ReactNode } from 'react';
import s from './Button.module.scss';
import classNames from 'classnames';
import {Link} from "react-router-dom";

interface PropsTypes {
  type?: 'button' | 'submit';
  className?: string;
  children: ReactNode;
  filled?: boolean;
  outlined?: boolean;
  variant?: 'default' | 'circle';
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Button: FC<PropsTypes> = memo(
  ({ type = 'button', className = '', children, variant = 'default', filled, href, onClick }) => {
    return (
      <>
        {href ? (
          <Link
            className={classNames(s.Button, className, {
              [s.filled]: filled,
              [s.circle]: variant === 'circle',
            })}
            to={href}
          >
            {children}
          </Link>
        ) : (
          <button
            className={classNames(s.Button, className, {
              [s.filled]: filled,
              [s.circle]: variant === 'circle',
            })}
            type={type}
            onClick={onClick}
          >
            {children}
          </button>
        )}
      </>
    );
  },
);
