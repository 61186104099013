import {router} from "@/app/router/const/router";

export enum MainPageHashesEnum {
    participators = 'participators',
    sponsors = 'sponsors',
    about = 'about',
}
export const menu = [
    {
        name: 'Про Конкурс',
        value: 'about',
        route: `${router.main()}#${MainPageHashesEnum.about}`
    },
    {
        name: 'Учасниці',
        value: 'participants',
        route: `${router.main()}#${MainPageHashesEnum.participators}`
    },
    {
        name: 'Голосування',
        value: 'vote',
        route: router.votes()
    },
    {
        name: 'Тури',
        value: 'tours',
        route: router.tours()
    },
    {
        name: 'Жюрі',
        value: 'jury',
        route: router.jury(),
    },
    {
        name: 'Новини',
        value: 'news',
        route: router.news(),
    },
    {
        name: 'Спонсори',
        value: 'sponsors',
        route: `${router.main()}#${MainPageHashesEnum.sponsors}`
    },
    {
        name: 'Переможниці',
        value: 'winners',
        route: router.winners()
    },
]