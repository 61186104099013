import { FC, memo } from 'react';
import s from './SocialList.module.scss';
import {YoutubeIcon, FacebookIcon, InstIcon} from "@/shared/assets";


interface PropsTypes {}

export const SocialList: FC<PropsTypes> = memo(() => {
  return (
    <ul className={s.SocialList}>
        <li className={s.item}>
          <a href={'https://www.youtube.com/'} className={s.link}>
            <YoutubeIcon className={s.img}/>
          </a>
        </li>
        <li className={s.item}>
          <a href={'https://www.instagram.com/'} className={s.link}>
            <InstIcon className={s.img}/>
          </a>
        </li>
        <li className={s.item}>
          <a href={'https://www.facebook.com/'} className={s.link}>
            <FacebookIcon className={s.img}/>
          </a>
        </li>
    </ul>
  );
});
