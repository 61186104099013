import { RouteProps } from 'react-router-dom'
import { router } from '@/app/router/const/router'
import {MainPage} from "@/pages/Main";
import {ParticipatorPage} from "@/pages/Participator";
import {VotesPage} from "@/pages/Votes";
import {ToursPage} from "@/pages/Tours";
import {JuryPage} from "@/pages/Jury";
import {NewsPage} from "@/pages/News";
import {NewsOnePage} from "@/pages/NewsOne";
import {WinnersPage} from "@/pages/Winners/ui";

export enum AppRoutesNames {
  MAIN = 'main',
  PARTICIPATOR = 'participator',
  VOTES = 'votes',
  TOURS = 'tours',
  JURY = 'jury',
  NEWS = 'news',
  NEWS_ONE = 'one_news',
  WINNERS = 'winners',
}



export type AppRouteProps = RouteProps

export const routerConfig: Record<AppRoutesNames, AppRouteProps> = {
  [AppRoutesNames.MAIN]: {
    path: router.main(),
    element: <MainPage/>,
  },
  [AppRoutesNames.PARTICIPATOR]: {
    path: router.participator(':participatorId'),
    element: <ParticipatorPage/>,
  },
  [AppRoutesNames.VOTES]: {
    path: router.votes(),
    element: <VotesPage/>,
  },
  [AppRoutesNames.TOURS]: {
    path: router.tours(),
    element: <ToursPage/>,
  },
  [AppRoutesNames.JURY]: {
    path: router.jury(),
    element: <JuryPage/>,
  },
  [AppRoutesNames.NEWS]: {
    path: router.news(),
    element: <NewsPage/>,
  },
  [AppRoutesNames.NEWS]: {
    path: router.news(),
    element: <NewsPage/>,
  },
  [AppRoutesNames.NEWS_ONE]: {
    path: router.newsOne(':newsId'),
    element: <NewsOnePage/>,
  },
  [AppRoutesNames.WINNERS]: {
    path: router.winners(),
    element: <WinnersPage/>,
  },
}