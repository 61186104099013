import React, { memo, Suspense, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import s from './AppRoutes.module.scss'
// import { OnlyNonAuthLayout } from '@/shared/layouts/OnlyNonAuthLayout/OnlyNonAuthLayout'
// import { SecureLayout } from '@/shared/layouts/SecureLayout/SecureLayout'
//
// import Loader from '../../../shared/UI/Loader/Loader'
import { AppRouteProps, routerConfig } from '@/app/router/config/router.config'
import {Loader} from "@/shared/UI/Loader/Loader";
import classNames from "classnames";

const AppRoutes = () => {
  const renderWithWrapper = useCallback((route: AppRouteProps) => {
    const element = (
      <Suspense
        // fallback={<Loader style={{ position: 'relative', minHeight: '100vh' }} />}
          fallback={<Loader className={classNames(s.loader, 'container')}/>}
      >
        {route.element}
      </Suspense>
    )

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
            element
          // route.nonAuthOnly ? (
          //   <OnlyNonAuthLayout>{element}</OnlyNonAuthLayout>
          // ) : route.authOnly ? (
          //   <SecureLayout>{element}</SecureLayout>
          // ) : (
          //   element
          // )
        }
      />
    )
  }, [])

  return <Routes>{Object.values(routerConfig).map(renderWithWrapper)}</Routes>
}

export default memo(AppRoutes)
