import {FC, memo} from "react";
import s from './Burger.module.scss'

interface PropsTypes {
    isOpened?: boolean
    onClick?: () => void
}

export const Burger: FC<PropsTypes> = memo(({isOpened, onClick}) => {
    return (
        <div className={`${s.Burger} ${(isOpened ? s.opened : '')}`} onClick={onClick && onClick}>
            <div className={s.wrapper}>
                <div className={s.line}></div>
                <div className={s.line}></div>
            </div>
        </div>
    );
});