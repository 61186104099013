import { FC, memo } from "react";
import s from './Loader.module.scss'
import classNames from "classnames";

interface PropsTypes {
    className?: string
}
export const Loader: FC<PropsTypes> = memo(({className}) => {
  return (
    <div className={classNames(s.Loader, className)}>
        Loading...
    </div>
  );
});