import { FC, memo, useState } from 'react';
import s from './AppHeader.module.scss';
import Logo from '@/shared/assets/logo.svg?react';
import { Burger } from '@/shared/UI/Burger/Burger';
import { Menu } from '@/widgets/AppHeader/ui/Menu/Menu';
import { Button } from '@/shared/UI/Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import { TABLET_MEDIA_QUERY } from '@/shared/const/reponsive.const';
import {Link} from "react-router-dom";
import { router } from '@/app/router/const/router';

interface PropsTypes {}

export const AppHeader: FC<PropsTypes> = memo(() => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);
  return (
    <>
      <div className={`${s.AppHeader} container`}>
        <div className={s.wrapper}>
          <div>
            <Link className={s.link} to={router.main()}>
              <Logo className={s.logo} />
            </Link>
          </div>
          <div className={s.title}>
            Mis <br /> Zhytomyr
          </div>
          <div className={s.right}>
            {isTablet && <Button className={s.btn} href={router.votes()}>проголосувати</Button>}
            <Burger onClick={() => setIsMenuOpened(!isMenuOpened)} />
          </div>
        </div>
      </div>

      {isMenuOpened && <Menu onClose={() => setIsMenuOpened(false)} />}
    </>
  );
});